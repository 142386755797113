/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal, Table, Upload, Button, Divider, Flex } from "antd";
import { UploadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

import { toast } from "react-toastify";
// import { nameHasSpecialChar, hasNumber } from '../../../Appointment';
import {
  clearRegisterInmateStore,
  getInmateData,
  getUserDataInfo,
  registerInmateData,
} from "../../../../redux/actions";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../../interfaces";
import ExcelTemplateDownload from "components/downloadTemplate";

interface Inmate {
  inmate_code: string;
  first_name: string;
  last_name: string;
  mother_name: string;
  father_name: string;
  city: string;
}

const requiredColumns = [
  {
    title: "First name",
    width: 100,
    dataIndex: "first_name",
    key: "first_name",
    fixed: "left",
  },
  {
    title: "Last name",
    width: 100,
    dataIndex: "last_name",
    key: "last_name",
  },
];

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const requiredColumnKeys = requiredColumns
  ? requiredColumns.map((col) => col.dataIndex)
  : [];

const UploadInmates: React.FC<{ open: boolean; handleClose: () => void }> = ({
  open,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  const { getRegisteredInmate } = userSelector((user) => user);

  const handleFileUpload = (file: File) => {
    const isXlsx =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isXlsx) {
      toast.error("You can only upload XLSX files!");
      return Upload.LIST_IGNORE;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target?.result;
      if (binaryStr) {
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (jsonData.length) {
          const headers: any = jsonData[0];
          const headerKeys = headers.map((header: string) =>
            header.toLowerCase().replace(" ", "_")
          );
          const isValid = requiredColumnKeys.every((key: any) =>
            headerKeys.includes(key)
          );

          if (!isValid) {
            toast.error(
              "The uploaded file does not contain the required columns: First name, Last name."
            );
            return Upload.LIST_IGNORE;
          }

          const tableColumns = headers.map((header: string) => ({
            title: header,
            dataIndex: header.toLowerCase().replace(" ", "_"),
            key: header.toLowerCase().replace(" ", "_"),
          }));

          const tableData = jsonData.slice(1).map((row: any, index: number) => {
            const rowData: { key: number; [key: string]: any } = { key: index };
            row.forEach((cell: any, cellIndex: number) => {
              rowData[headerKeys[cellIndex]] = cell;
            });
            return rowData;
          });

          setColumns(tableColumns);
          setData(tableData);
        }
      }
    };
    reader.readAsBinaryString(file);
    return false;
  };

  const handleSaveUploadedFile = () => {
    const saveInmateWithIssues: { inmate_code: string; inmate_name: string }[] =
      [];

    // const isInvalid = (value: string) => nameHasSpecialChar(value === '' ? '' : value) || hasNumber(value);

    const newInmateList = data
      ? data
          .map((item) => {
            //   if (
            //       isInvalid(item?.first_name || '') ||
            //       isInvalid(item?.last_name || '') ||
            //       isInvalid(item?.mother_name || '') ||
            //       isInvalid(item?.father_name || '') ||
            //       isInvalid(item?.zone)
            //   ) {
            //       saveInmateWithIssues.push({
            //           inmate_code: item?.inmate_code,
            //           inmate_name: `${item?.last_name} ${item?.first_name}`,
            //       });
            //       return null;
            //   } else {

            //   }
            return item;
          })
          .filter(Boolean)
      : [];

    if (saveInmateWithIssues.length > 0) {
      const issuesMessage = saveInmateWithIssues
        .map((issue) => `${issue.inmate_code}: ${issue.inmate_name}`)
        .join("\n");
      toast.error(
        `The following uploaded inmate information contains issues:\n${issuesMessage}`
      );
    }

    if (saveInmateWithIssues.length === 0) {
      const inmateListData = newInmateList as Inmate[];
      dispatch(registerInmateData({ data: inmateListData }));
    }
  };

  useEffect(() => {
    if (getRegisteredInmate.data?.data?.success) {
      toast.success(getRegisteredInmate.data?.message);
      dispatch(clearRegisterInmateStore());
      setData([]);
      setColumns([]);
      dispatch(
        getInmateData({
          inmateWithWallet: "NO",
        })
      );
      dispatch(getUserDataInfo());
    }

    if ([500, 400].includes(getRegisteredInmate.data?.status)) {
      if (getRegisteredInmate.data?.data) {
        toast.error(`${getRegisteredInmate.data?.data.message}`);
        dispatch(clearRegisterInmateStore());
      } else {
        const issuesMessage =
          getRegisteredInmate.data?.data?.error &&
          getRegisteredInmate.data?.data?.error?.inmates
            .map((issue: any) => `${issue.inmate_code}: ${issue.inmate_name}`)
            .join("\n");
        toast.error(
          `${getRegisteredInmate.data?.data.message}:\n${issuesMessage}`
        );
        dispatch(clearRegisterInmateStore());
      }
    }
  }, [getRegisteredInmate.data]);

  return (
    <Modal
      open={open}
      onCancel={() => {
        handleClose();
        setData([]);
        setColumns([]);
      }}
      footer={null}
      width={1000}
    >
      <div
        className="display-row-items-space-bt"
        style={{ marginTop: "20px", width: "100%" }}
      >
        <Flex justify="space-between" style={{ width: "100%" }}>
          <Upload beforeUpload={handleFileUpload} showUploadList={false}>
            <Button ghost type="primary" icon={<UploadOutlined />}>
              Select File
            </Button>
          </Upload>
          <ExcelTemplateDownload
            title="Download inmate list template"
            downloadUrl={"upload-inmate-excel-template"}
          />
        </Flex>

        {data && data.length ? (
          <Button
            type="primary"
            icon={<CloudUploadOutlined />}
            onClick={handleSaveUploadedFile}
            style={{ marginRight: "20px" }}
            loading={getRegisteredInmate.loading}
          >
            Submit
          </Button>
        ) : null}
      </div>

      <Divider style={{ margin: "8px" }} />
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        pagination={{ pageSize: 50 }}
        scroll={{ x: 1500, y: 600 }}
      />
    </Modal>
  );
};

export default UploadInmates;
