import { call, put, takeEvery } from "redux-saga/effects";
import { DOWNLOAD_INMATE_CONTACT_TEMPLATE_START } from "constants/actionType/inmates";
import { fetchData } from "../../../helpers";
import {
  downloadInmateContactTemplatesSuccess,
  downloadInmateContactTemplatesFail,
} from "../../actions";

interface ResponseGenerator {
  data: any;
}

function* downloadInmateContactTemplate() {
  try {
    const response: ResponseGenerator = yield call(
      fetchData,
      "prison/audio/upload-inmate-contact-template"
    );
    if (response?.data?.error) {
      yield put(
        downloadInmateContactTemplatesFail(response.data || "Unknown error")
      );
    } else {
      yield put(downloadInmateContactTemplatesSuccess(response));
    }
  } catch (e: any) {
    yield put(downloadInmateContactTemplatesFail(e.message || "Unknown error"));
  }
}

export default function* getInmateConactTemplate() {
  yield takeEvery(
    DOWNLOAD_INMATE_CONTACT_TEMPLATE_START,
    downloadInmateContactTemplate
  );
}
