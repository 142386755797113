/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Button,
  Popconfirm,
  Tag,
  Modal,
  TabsProps,
  Tabs,
  Radio,
  RadioChangeEvent,
  Flex,
  Empty,
  Form,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import "./updateDelete";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import {
  getCallOfficerData,
  getInmateData,
  getPrisonsData,
  getUserDataInfo,
} from "../../../redux/actions";
import "./updateDelete.css";
import {
  clearUpdateDeleteInmatesStore,
  updateDeleteInmateData,
} from "../../../redux/actions/inmates/updateDeleteInmate";
import RegisterNewInmate from "./registerNewInmate";
import { Input, InputRef } from "antd";
import { ColumnType, FilterConfirmProps } from "antd/es/table/interface";
import {
  SearchOutlined,
  SwapOutlined,
  SwitcherOutlined,
  PhoneOutlined,
  UnlockOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useRef } from "react";
import Highlighter from "react-highlight-words";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  INMATE_CRITICAL_STATUS,
  INMATE_NORMAL_STATUS,
} from "../../../constants/inamteStatus";
import { MdKeyboardArrowRight } from "react-icons/md";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UploadInmates from "./UploadInamtes";
import CallingDays from "./callingConfiguration/CallingDays";
import ConfigureCallingTime from "./callingConfiguration/ConfigureCallingTime";
import UploadInmatesPhoneNumber from "./UploadInmatePhoneNumber";
import EmptyTable from "components/common/emptyTable";
import TransferInmateModal from "./TransferImate";
import LockInmateAccount from "./LockInmateAccount";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const tabsList: TabsProps["items"] = [
  {
    key: "1",
    label: "Calling days",
    children: <CallingDays />,
  },
  {
    key: "2",
    label: "Configure",
    children: <ConfigureCallingTime />,
  },
];

interface DataType {
  id: string | number;
  name: string;
  username: number;
  address: string;
  user_profiles: string[];
  userRoleId: number;
  gender: string;
  birthDate: string;
  status: string | boolean | undefined;
  inmate_code: string | number;
}

const options = [
  { label: "New inmate", value: "newInmate" },
  { label: "Upload inmates", value: "uploadInmates" },
  { label: "Upload phone numbers", value: "uploadInmatePhoneNumber" },
  { label: "Invoice", value: "invoice" },
  { label: "Settings", value: "settings" },
];

const InmatesList: React.FC<any> = () => {
  const dispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const [open, setOpen] = useState(false);
  const [changePage, setChangePage] = useState<string>("default");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [inmateId, setInmateId] = useState<string | number>();
  const [inmateCode, setInmateCode] = useState<string | number>();
  const [openUploadInmateModal, setOpenUploadInmateModal] =
    useState<boolean>(false);
  const [
    openUploadInmatePhoneNumberModal,
    setOpenUploadInmatePhoneNumberModal,
  ] = useState<boolean>(false);
  const [openRegisterInmate, setRegisterInmate] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const [openCallingSettingsModel, setOpenCallingSettingModel] =
    useState(false);
  const [groupedButtons, setGroupedButtons] = useState("");
  const [openTransferInmateModal, setOpenTransferInmateModal] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
    showSizeChanger: true,
  });
  const [filterPage, setFilterPage] = useState({
    current: 1,
    pageSize: 100,
  });

  const [form] = Form.useForm();
  const [nameFilter, setNameFilter] = useState("");
  const [inmateCodeFilter, setInmateCodeFilter] = useState("");
  const [openLockInmateAccountModal, setOpenLockInmateAccountModal] =
    useState(false);

  const { getInmates, getUserData, updateDeleteInmates } = userSelector(
    (user) => user
  );

  const roleId =
    getUserData?.data?.data !== undefined
      ? getUserData?.data?.data[0].role_id
      : undefined;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const onChangeTab = (key: string) => {
    console.log(key);
  };

  const showModal = (id: string | number | undefined) => {
    setInmateId(id);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleCloseUploadInmateModal = () => {
    setOpenUploadInmateModal(false);
    setGroupedButtons("");
  };

  const handleCloseUploadInmatePhoneNumberModal = () => {
    setOpenUploadInmatePhoneNumberModal(false);
    setGroupedButtons("");
  };

  const handleOpenUpdateInmateModal = () => {
    setOpenUploadInmateModal(true);
  };

  const onHandleOpenCallingSettingModal = () => {
    setOpenCallingSettingModel(true);
  };

  const onHandleOpenInmatePhoneNumberModal = () => {
    setOpenUploadInmatePhoneNumberModal(true);
  };

  const onHandleCloseCallingSettingModal = () => {
    setOpenCallingSettingModel(false);
    setGroupedButtons("");
  };

  const onHandleOpenRegisterInmate = () => {
    setRegisterInmate(true);
    setGroupedButtons("");
  };
  const onHandleCloseRegisterInmate = () => setRegisterInmate(false);

  const onSearchFromBackend = () => {
    const values = form.getFieldsValue();
    setNameFilter(values.name || "");
    setInmateCodeFilter(values.inmateCode || "");
  };

  const onRefreshList = () => {
    dispatch(
      getInmateData({
        name: "",
        inmate_code: "",
        inmateWithWallet: "NO",
        page: filterPage.current,
        per_page: filterPage.pageSize,
      })
    );
  };

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<DataType> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "left",
      width: 60,
      render: (_, data: any) => {
        return (
          <>
            {data?.status !== INMATE_CRITICAL_STATUS ? (
              <Tag color={"green"}>{"Normal"}</Tag>
            ) : (
              <Tag color={"red"}>{"Critical"}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Inmate code",
      dataIndex: "inmate_code",
      key: "inmate_code",
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 200,
      render: (text) => <a>{text}</a>,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 80,
    },
    {
      title: "Birth date",
      dataIndex: "birthDate",
      key: "birthDate",
      width: 150,
    },

    {
      title: "Father names",
      dataIndex: "father_name",
      key: "father_name",
      width: 150,
    },
    {
      title: "Mother names",
      dataIndex: "mather_name",
      key: "mather_name",
      width: 150,
    },
    {
      title: "Contacts",
      dataIndex: "contacts",
      key: "contacts",
      width: 50,
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 120,
      render: (data) => {
        const critical: { id: string; status: string } = {
          id: data?.id,
          status: INMATE_CRITICAL_STATUS,
        };
        const normal: { id: string; status: string } = {
          id: data?.id,
          status: INMATE_NORMAL_STATUS,
        };

        return (
          <Space.Compact>
            {!data?.lock_account && (
              <>
                <Button
                  onClick={() => {
                    showModal(data?.id);
                    setInmateCode(data?.inmate_code);
                  }}
                  type="text"
                  icon={<PhoneOutlined />}
                />

                {data?.status !== INMATE_CRITICAL_STATUS ? (
                  <Popconfirm
                    placement="right"
                    title="Are you sure you want to update status to critical"
                    onConfirm={() => updateInmateStatus(critical)}
                    okText="Yes"
                    cancelText="Cancel"
                    disabled={[2, 7].includes(roleId) === true ? false : true}
                  >
                    <Button type="text" icon={<SwitcherOutlined />} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    placement="right"
                    title="Are you sure you want to update status to normal?"
                    onConfirm={() => updateInmateStatus(normal)}
                    okText="Yes"
                    cancelText="Cancel"
                    disabled={[2, 7].includes(roleId) === true ? false : true}
                  >
                    <Button type="text" icon={<SwitcherOutlined />} />
                  </Popconfirm>
                )}

                <Button
                  type="text"
                  icon={<SwapOutlined />}
                  onClick={() => {
                    setInmateCode(data?.inmate_code);
                    setOpenTransferInmateModal(true);
                  }}
                />
              </>
            )}
            <Button
              type="text"
              icon={
                !data?.lock_account ? (
                  <UnlockOutlined />
                ) : (
                  <LockOutlined style={{ color: "red" }} />
                )
              }
              onClick={() => {
                setInmateCode(data?.inmate_code);
                setOpenLockInmateAccountModal(true);
              }}
            />
          </Space.Compact>
        );
      },
    },
  ];

  const updateInmateStatus = (data: any) => {
    const userData: any = data;
    dispatch(updateDeleteInmateData(userData));
  };

  const inmateData = !getInmates?.data?.data
    ? []
    : getInmates?.data?.data?.details;

  const data: DataType[] = inmateData?.map((item: any) => {
    return {
      birthDate: item?.birth_date,
      city: item?.city,
      district: item?.district,
      father_name: item?.father_name,
      name: `${item?.first_name} ${item?.last_name}`,
      gender: item?.gender === "F" ? "Female" : "Male",
      id: item?.id,
      inmate_code: item?.inmate_code,
      mather_name: item?.mather_name,
      province: item?.state,
      status: item?.status,
      contacts: item?.contacts,
      lock_account: item?.lock_account,
    };
  });

  const onHandleChangeGroupedBtn = ({
    target: { value },
  }: RadioChangeEvent) => {
    setGroupedButtons(value);
  };

  const handleTableChange = (newPagination: any) => {
    setFilterPage({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });
  };

  useEffect(() => {
    if (groupedButtons === "newInmate") {
      onHandleOpenRegisterInmate();
    }

    if (groupedButtons === "uploadInmates") {
      handleOpenUpdateInmateModal();
    }

    if (groupedButtons === "settings") {
      onHandleOpenCallingSettingModal();
    }

    if (groupedButtons === "uploadInmatePhoneNumber") {
      onHandleOpenInmatePhoneNumberModal();
    }

    if (groupedButtons === "invoice") {
      navigate("/inmates-calls-invoices");
    }
  }, [groupedButtons]);

  useEffect(() => {
    dispatch(getPrisonsData(`v2/admin/retrieve-prisons`));
    dispatch(getCallOfficerData());
  }, [dispatch, getInmates?.data?.data]);

  useEffect(() => {
    dispatch(getUserDataInfo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getInmateData({
        inmateWithWallet: "NO",
        page: filterPage.current,
        per_page: filterPage.pageSize,
      })
    );
  }, [dispatch, filterPage]);

  useEffect(() => {
    if (getInmates?.data?.data) {
      const inmateData = getInmates?.data?.data;
      setPagination({
        total: inmateData?.total_inmates,
        current: inmateData?.current_page,
        pageSize: inmateData?.per_page,
        showSizeChanger: true,
      });
    }
  }, [getInmates?.data?.data]);

  useEffect(() => {
    if (updateDeleteInmates?.data?.data?.success) {
      toast.success(updateDeleteInmates?.data?.message);
      dispatch(
        getInmateData({
          inmateWithWallet: "NO",
          page: filterPage.current,
          per_page: filterPage.pageSize,
        })
      );
      dispatch(getUserDataInfo());
      dispatch(clearUpdateDeleteInmatesStore());
    }
  }, [updateDeleteInmates?.data]);

  const inmateShotReport = getInmates?.data?.data;

  useEffect(() => {
    dispatch(
      getInmateData({
        name: nameFilter,
        inmate_code: inmateCodeFilter,
        inmateWithWallet: "NO",
        page: filterPage.current,
        per_page: filterPage.pageSize,
      })
    );
  }, [dispatch, nameFilter, inmateCodeFilter]);

  const customLoading = {
    spinning: getInmates.loading,
    indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
  };

  return (
    <>
      {changePage === "default" ? (
        <>
          <Flex style={{ width: "100%" }} vertical>
            <Flex
              justify="space-between"
              align="center"
              style={{ width: "100%" }}
            >
              {[2, 7, 5].includes(roleId) === true ? (
                <Flex justify="flex-end" style={{ padding: "10px" }}>
                  <Radio.Group
                    buttonStyle="solid"
                    options={options}
                    onChange={onHandleChangeGroupedBtn}
                    value={groupedButtons}
                    optionType="button"
                  />
                </Flex>
              ) : null}

              <Space.Compact>
                <Form
                  form={form}
                  layout="inline"
                  onFinish={onSearchFromBackend}
                >
                  <Space.Compact block>
                    <Form.Item name="name" noStyle>
                      <Input placeholder="Filter by name" />
                    </Form.Item>
                    <Form.Item name="inmateCode" noStyle>
                      <Input placeholder="Filter by inmate code" />
                    </Form.Item>
                    <Form.Item noStyle>
                      <Button
                        ghost
                        type="primary"
                        icon={<SearchOutlined />}
                        htmlType="submit"
                      />
                    </Form.Item>
                  </Space.Compact>
                </Form>
                <Button
                  ghost
                  type="primary"
                  onClick={onRefreshList}
                  icon={<ReloadOutlined />}
                  style={{ marginRight: "12px" }}
                />
              </Space.Compact>
            </Flex>

            <Flex justify="flex-start" className="white-background">
              <Flex gap="small" justify="center" vertical>
                <span className="default-grey-colo">All inmates</span>
                <span className="bold-text">
                  {inmateShotReport?.all_inmates}
                </span>
              </Flex>
              <div className="separator__col"></div>
              <Flex gap="small" justify="center" vertical>
                <span className="default-grey-colo">
                  {inmateShotReport?.critical > 1
                    ? "Critical cases"
                    : "Critical case"}
                </span>
                <span className="bold-text">{inmateShotReport?.critical}</span>
              </Flex>
              <div className="separator__col"></div>
              <Flex gap="small" justify="center" vertical>
                <span className="default-grey-colo">
                  {inmateShotReport?.normal > 1
                    ? "Normal cases"
                    : "Normal case"}
                </span>
                <span className="bold-text">{inmateShotReport?.normal}</span>
              </Flex>
              <div className="separator__col"></div>
              <Flex gap="small" justify="center" vertical>
                <span className="default-grey-colo">
                  {inmateShotReport?.female > 1 ? "Females" : "Female"}
                </span>
                <span className="bold-text">{inmateShotReport?.female}</span>
              </Flex>
              <div className="separator__col"></div>
              <Flex gap="small" justify="center" vertical>
                <span className="default-grey-colo">
                  {inmateShotReport?.male > 1 ? "Males" : "Male"}
                </span>
                <span className="bold-text">{inmateShotReport?.male}</span>
              </Flex>
            </Flex>

            <Table
              size="small"
              columns={columns}
              dataSource={data}
              rowKey={(record) => record.id}
              pagination={data.length > 8 ? pagination : false}
              loading={customLoading}
              onChange={handleTableChange}
              locale={{
                emptyText: (
                  <EmptyTable
                    title={"No inmate yet"}
                    subTitle={"There is no inmate yet."}
                    Icon={<Empty />}
                    button={undefined}
                  />
                ),
              }}
            />
          </Flex>
        </>
      ) : null}
      <Modal
        title="What do you want to do?"
        open={open}
        onCancel={handleCancel}
        closable={false}
        footer={[]}
      >
        <div className="custom-modal-btn-group">
          <div
            className="custom-btn"
            onClick={() =>
              navigate("/bound-phone-number", { state: { inmateId: inmateId } })
            }
          >
            <span>Add phone number to inmate</span>
            <MdKeyboardArrowRight size={22} />
          </div>
          <div
            className="custom-btn"
            onClick={() =>
              navigate("/inmate-phone-numbers", {
                state: { inmateId: inmateId },
              })
            }
          >
            <span>Display this inmate phone numbers</span>
            <MdKeyboardArrowRight size={22} />
          </div>
          <div
            style={{ display: "none" }}
            className="custom-btn"
            onClick={() =>
              navigate("/inmate-calls", { state: { inmateCode: inmateCode } })
            }
          >
            <span>Display recorded calls</span>
            <MdKeyboardArrowRight size={22} />
          </div>
          <div
            className="custom-btn"
            onClick={() =>
              navigate("/inmate-calls-history", {
                state: { inmateCode: inmateCode },
              })
            }
          >
            <span>Display inmate call history</span>
            <MdKeyboardArrowRight size={22} />
          </div>
        </div>
        <div className="custom-modal-footer">
          <Button
            key="submit"
            type="primary"
            onClick={handleCancel}
            className="black-background-color"
          >
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
        width={700}
        open={openCallingSettingsModel}
        onCancel={onHandleCloseCallingSettingModal}
        footer={[]}
      >
        <Tabs defaultActiveKey="1" items={tabsList} onChange={onChangeTab} />
      </Modal>

      <RegisterNewInmate
        setChangePage={setChangePage}
        open={openRegisterInmate}
        onClose={onHandleCloseRegisterInmate}
      />
      <UploadInmates
        open={openUploadInmateModal}
        handleClose={handleCloseUploadInmateModal}
      />
      <UploadInmatesPhoneNumber
        open={openUploadInmatePhoneNumberModal}
        handleClose={handleCloseUploadInmatePhoneNumberModal}
      />
      <TransferInmateModal
        open={openTransferInmateModal}
        onClose={() => setOpenTransferInmateModal(false)}
        inmate={inmateCode}
      />
      <LockInmateAccount
        open={openLockInmateAccountModal}
        onClose={() => setOpenLockInmateAccountModal(false)}
        inmate={inmateCode}
      />
      {/* <UploadBulkAmounts open={onHandleOpenUploadBulkAmount}/> */}
    </>
  );
};

export default InmatesList;
