import { Alert, Button, Flex, Form, Modal } from "antd";
import { IUserSelectorType } from "interfaces";
import React, { useEffect } from "react";
import { AlertOutlined } from "@ant-design/icons";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearTransferInmate,
  transferInmateData,
  getInmateData,
  getPublicItemsData,
} from "redux/actions";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface LockInmateAccountProps {
  open: boolean;
  onClose: () => void;
  inmate: number | string | undefined;
}

const LockInmateAccount: React.FC<LockInmateAccountProps> = ({
  open,
  onClose,
  inmate,
}) => {
  const dispatch = useDispatch();
  const { transferInmate } = userSelector((user: any) => user);

  const onHandleCloseModal = () => {
    onClose();
    dispatch(clearTransferInmate());
  };

  const onSubmit = () => {
    dispatch(
      transferInmateData({
        facility: "",
        inmate_code: inmate,
        lock_account: true,
      })
    );
  };

  useEffect(() => {
    if (transferInmate.data?.success) {
      toast.success(transferInmate.message);
      onHandleCloseModal();
      dispatch(
        getInmateData({
          inmateWithWallet: "NO",
        })
      );
    }

    if (transferInmate.error) {
      toast.error(transferInmate.message);
      dispatch(clearTransferInmate());
    }
  }, [transferInmate.error, transferInmate.data]);

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onCancel={onClose}
      title={
        <Flex align="end">
          <AlertOutlined style={{ fontSize: "45px", color: "red" }} />
          <span>{"Inmate Account Suspension"}</span>
        </Flex>
      }
      footer={
        <Flex justify="space-between" style={{ width: "100%" }}>
          <Button onClick={onHandleCloseModal} type="primary" ghost danger>
            Cancel
          </Button>
          <Button
            loading={transferInmate?.loading}
            onClick={onSubmit}
            type="primary"
            ghost
          >
            Submit
          </Button>
        </Flex>
      }
    >
      <Form layout="vertical">
        <Form.Item>
          <Alert
            type="error"
            style={{ color: "red" }}
            message={`You are about to lock ${inmate}'s RCS case number account. 
                Once locked, you will not be able to unlock it. 
                The inmate will no longer be able to access their wallet, place orders, or receive funds.`}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LockInmateAccount;
