import { all, fork } from "redux-saga/effects";
import getIncomingAppointment from "./sagas/appointments/getIncomingAppointment";
import getOutgoingAppointment from "./sagas/appointments/getOutgoingAppointment";
import getRequestAppointment from "./sagas/appointments/requestAppointment";
import fetchUserData from "./sagas/getUserData";
import fetchInmates from "./sagas/inmates/getInmates";
import getRegisterInmates from "./sagas/inmates/registerInmates";
import getSignInUser from "./sagas/login";
import fetchRegisteredUser from "./sagas/registerUser";
import fetchUserRoles from "./sagas/roles";
import fetchPrisonData from "./sagas/users/getPrison";
import fetchUserAccountData from "./sagas/users/userAccounts";
import fetchSummary from "./sagas/summary/getSummary";
import getUpdateDeleteInmates from "./sagas/inmates/updateDeleteInmate";
import getApproveRejectAppointment from "./sagas/appointments/approveRejectAppointment";
import getSendMoney from "./sagas/sendMoney/sendMoney";
import fetchInmatesWallets from "./sagas/inmates/inmatesWallets";
import fetchFinanceReport from "./sagas/finance/getFinanceReport";
import fetchCorrectionalFacilityData from "./sagas/users/getCorrectionalFacility";
import fetchShopSummaryReport from "./sagas/shop/summaryReport";
import fetchGetProduct from "./sagas/shop/getProduct";
import getSaveNewProduct from "./sagas/shop/saveNewProduct";
import getUpdateProduct from "./sagas/shop/updateProduct";
import getDeleteProduct from "./sagas/shop/deleteProduct";
import fetchGetProductSoldList from "./sagas/shop/getProductSoldList";
import fetchGetProductSummaryReport from "./sagas/shop/productSummaryReport";
import fetchGetOrders from "./sagas/orders/getOrders";
import fetchGetOrdersDone from "./sagas/orders/getOrderDone";
import fetchGetConfirmOder from "./sagas/orders/confirmOrder";
import fetchGetMakeOder from "./sagas/orders/makeOrder";
import getUpdateDeleteUserData from "./sagas/users/updateDeleteUser";
import fetchRwandaProvinces from "./sagas/rwandaProvinces/getRwandaProvinces";
import fetchRecordCashAmount from "./sagas/finance/recordCashAmount";
import fetchCreateOrderFile from "./sagas/orders/createOrderFile";
import fetchGetOrderFile from "./sagas/orders/getOrderFile";
import fetchGetCreateOrderFileDetails from "./sagas/orders/getOrderFileDetails";
import fetchApproveOrderFile from "./sagas/orders/approveOrderFile";
import fetchGetNotification from "./sagas/notifications/getNotification";
import fetchReadNotification from "./sagas/notifications/readNotification";
import fetchDeliveryNote from "./sagas/orders/deliveryNote";
import fetchOutGoingMoney from "./sagas/sendMoney/outGoingMoney";
import fetchGetOutGoingMoney from "./sagas/sendMoney/getOutGoingMoney";
import fetchPurchaseOrder from "./sagas/orders/purchaseOrder";
import fetchPublicItems from "./sagas/publicItems/publicItems";
import getRegisterFacility from "./sagas/facility/registerFacility";
import getUpdateFacility from "./sagas/facility/updateFacility";
import getFacilityUser from "./sagas/facility/getFacilityUsers";
import getUpdateFacilityUser from "./sagas/facility/updateFacilityUsers";
import fetchInmatesWalletDetails from "./sagas/inmates/getInmateWalletDetails";
import fetchPrepareCoupon from "./sagas/inmates/prepareCoupon";
import getCreateNewSupplier from "./sagas/shop/createNewSupplier";
import fetchAllSupplier from "./sagas/shop/getAllProduct";
import fetchGetComment from "./sagas/orders/getComment";
import fetchCalculateOrderAmount from "./sagas/orders/calculateOrderAmount";
import fetchExecuteOrderAmount from "./sagas/orders/executeOrder";
import fetchDeliveryItems from "./sagas/orders/deliveryItems";
import fetchOutgoingTransactionHistory from "./sagas/sendMoney/ougoingTransactionHistoty";
import fetchSenderTransactionReport from "./sagas/sendMoney/senderTransactionReport";
import fetchSenderTransactionReportDetails from "./sagas/sendMoney/senderTransactionReportDetails";
import fetchInmatesTransactionReport from "./sagas/sendMoney/inmateTransactionReport";
import fetchInmatesTransactionReportDetails from "./sagas/sendMoney/inmateTransactionReportDetails";
import fetchGetOrdersDetails from "./sagas/orders/getOrderDetails";
import fetchCreateDeliveryNoteFile from "./sagas/orders/createDeliveryNoteFile";
import fetchGetDeliverNoteItems from "./sagas/orders/getDeliveryNoteItems";
import fetchCloseDeliveryNoteFile from "./sagas/orders/closeDeliveryNote";
import fetchGetAppointmentReport from "./sagas/appointments/appointmentReport";
import fetchInmatesWithZeroWallet from "./sagas/sendMoney/inmateWithZeroWallet";
import fetchSuperAdminInmates from "./sagas/superAdmin/getInmateList";
import fetchInmatesWalletsList from "./sagas/superAdmin/getInmateWalletList";
import fetchInmatesWIthZeroWalletsList from "./sagas/superAdmin/getInmateWithZeroWallet";
import fetchBookingReport from "./sagas/superAdmin/getBookingReport";
import fetchGetSummaryReport from "./sagas/superAdmin/getSummaryReport";
import fetchTransactionsReport from "./sagas/superAdmin/getTransactionsReport";
import getChangePassword from "./sagas/changePassword";
import getLogoutUser from "./sagas/logout";
import fetchCallOfficers from "./sagas/users/getCallOfficer";
import fetchRequestPhoneNumberLink from "./sagas/inmates/linkPhoneNumber";
import fetchInmatePhoneNumber from "./sagas/inmates/inmatePhonerNumber";
import fetchLinkOrDeletePhoneNumber from "./sagas/inmates/linkOrDeletePhoneNumber";
import fetchInmateCalls from "./sagas/inmates/inmateCalls";
import fetchPlayAudio from "./sagas/inmates/playAudio";
import fetchUploadDocument from "./sagas/orders/uploadDocument";
import fetchGeneratedBulkSaving from "./sagas/sendMoney/bulkSaving/createBulkSaving";
import fetchRetrieveBulkSavingFile from "./sagas/sendMoney/bulkSaving/retrieveBulkSavingFiles";
import fetchUploadBulkSaving from "./sagas/sendMoney/bulkSaving/uploadBulksavingDocument";
import fetchRecordedSaving from "./sagas/sendMoney/bulkSaving/recordSaving";
import fetchRetrieveBulkSavingFileDetails from "./sagas/sendMoney/bulkSaving/bulkSavingDetails";
import fetchRequestedApprovalOrRejection from "./sagas/sendMoney/bulkSaving/requestApprovalOrRejection";
import fetchRetrieveBulkTransactionFileDetails from "./sagas/sendMoney/bulkTransaction/bulkTransactionDetails";
import fetchRetrieveBulkTransactionFile from "./sagas/sendMoney/bulkTransaction/retrieveBulkTransactionFiles";
import fetchUploadBulkTransaction from "./sagas/sendMoney/bulkTransaction/uploadBulkTransactionDocument";
import fetchRecordedTransaction from "./sagas/sendMoney/bulkTransaction/recordTransaction";
import fetchRequestedApprovalOrRejectionTransaction from "./sagas/sendMoney/bulkTransaction/requestApprovalOrRejectionTransaction";
import fetchGeneratedBulkTransaction from "./sagas/sendMoney/bulkTransaction/createBulkTransaction";
import fetchDeleteUserAccount from "./sagas/deleteUserAccount";
import fetchUserProfile from "./sagas/users/getUserProfile";
import fetchUpdateUserProfile from "./sagas/users/updateUserProfile";
import fetchConfirmAppointment from "./sagas/appointments/confirmAppointment";
import fetchCompletedVisit from "./sagas/appointments/compledVisit";
import fetchGetAvailableTime from "./sagas/appointments/getAvailableTime";
import fetchGlobalSummary from "./sagas/globalSummary/getGlobalSummary";
import fetchCheckVisitId from "./sagas/videoMeeting/checkVisitId";
import fetchCheckWhoJoined from "./sagas/videoMeeting/checkWhoJoined";
import fetchVideoVisits from "./sagas/videoMeeting/videoVisits";
import fetchPendingVisits from "./sagas/videoMeeting/pendingVisit";
import fetchVisitDone from "./sagas/videoMeeting/visitDone";
import fetchSaveDeviceSignature from "./sagas/videoMeeting/saveDeviceSignature";
import fetchGetDevices from "./sagas/videoMeeting/getDevices";
import fetchLinkDeviceToVisit from "./sagas/videoMeeting/linkVisitToDevice";
import fetchAutoStartSession from "./sagas/videoMeeting/autoStartSession";
import fetchStartMeeting from "./sagas/videoMeeting/startMeeting";
import getActivityLogData from "./sagas/users/fetchActivityLog";
import fetchRequestInmateCallHistory from "./sagas/inmates/inmateCallHistory";
import getRequestCallingSettings from "./sagas/inmates/requestCallingSettings";
import retrieveCallingSettings from "./sagas/inmates/retrieveCallingSettings";
import fetchPreviewOrderFile from "./sagas/orders/previewOrderFile";
import fetchGetCells from "./sagas/orders/fetchCells";
import fetchRequestBindFileAndCell from "./sagas/orders/bindFileAndCell";
import fetchPurchaseOrderList from "./sagas/orders/retrievePurchaseOrderList";
import fetchManageZones from "./sagas/orders/manageZones";
import fetchEditOrder from "./sagas/orders/editOrder";
import fetchUploadBulkAmount from "./sagas/sendMoney/bulkTransaction/uploadBulkAmount";
import fetchVisitorItems from "./sagas/appointments/fetchVisitorItems";
import fetchVisitorLimit from "./sagas/facility/fetchVisitorLimit";
import fetchSetVisitorLimit from "./sagas/facility/visitorLimit";
import fetchTransferInmate from "./sagas/inmates/transferInmate";
import fetchCallInvoices from "./sagas/inmates/callInvoices";
import fetchSendBulkSMS from "./sagas/superAdmin/sendBulkSMS";
import getInmateConactTemplate from "./sagas/inmates/downloadInmateContactTemplate";

export default function* rootSaga() {
  yield all([
    fork(getSignInUser),
    fork(fetchUserData),
    fork(fetchRegisteredUser),
    fork(fetchUserRoles),
    fork(fetchUserAccountData),
    fork(getRegisterInmates),
    fork(fetchPrisonData),
    fork(fetchInmates),
    fork(getRequestAppointment),
    fork(getIncomingAppointment),
    fork(getOutgoingAppointment),
    fork(fetchSummary),
    fork(getUpdateDeleteInmates),
    fork(getApproveRejectAppointment),
    fork(getSendMoney),
    fork(fetchInmatesWallets),
    fork(fetchFinanceReport),
    fork(fetchCorrectionalFacilityData),
    fork(fetchShopSummaryReport),
    fork(fetchGetProduct),
    fork(getSaveNewProduct),
    fork(getUpdateProduct),
    fork(getDeleteProduct),
    fork(fetchGetProductSoldList),
    fork(fetchGetProductSummaryReport),
    fork(fetchGetOrders),
    fork(fetchGetOrdersDone),
    fork(fetchGetConfirmOder),
    fork(fetchGetMakeOder),
    fork(getUpdateDeleteUserData),
    fork(fetchRwandaProvinces),
    fork(fetchRecordCashAmount),
    fork(fetchCreateOrderFile),
    fork(fetchGetOrderFile),
    fork(fetchGetCreateOrderFileDetails),
    fork(fetchApproveOrderFile),
    fork(fetchGetNotification),
    fork(fetchReadNotification),
    fork(fetchDeliveryNote),
    fork(fetchOutGoingMoney),
    fork(fetchGetOutGoingMoney),
    fork(fetchPurchaseOrder),
    fork(fetchPublicItems),
    fork(getRegisterFacility),
    fork(getUpdateFacility),
    fork(getFacilityUser),
    fork(getUpdateFacilityUser),
    fork(fetchInmatesWalletDetails),
    fork(fetchPrepareCoupon),
    fork(getCreateNewSupplier),
    fork(fetchAllSupplier),
    fork(fetchGetComment),
    fork(fetchCalculateOrderAmount),
    fork(fetchExecuteOrderAmount),
    fork(fetchDeliveryItems),
    fork(fetchOutgoingTransactionHistory),
    fork(fetchSenderTransactionReport),
    fork(fetchSenderTransactionReportDetails),
    fork(fetchInmatesTransactionReport),
    fork(fetchInmatesTransactionReportDetails),
    fork(fetchGetOrdersDetails),
    fork(fetchCreateDeliveryNoteFile),
    fork(fetchGetDeliverNoteItems),
    fork(fetchCloseDeliveryNoteFile),
    fork(fetchGetAppointmentReport),
    fork(fetchInmatesWithZeroWallet),
    fork(fetchSuperAdminInmates),
    fork(fetchInmatesWalletsList),
    fork(fetchInmatesWIthZeroWalletsList),
    fork(fetchBookingReport),
    fork(fetchGetSummaryReport),
    fork(fetchTransactionsReport),
    fork(getChangePassword),
    fork(getLogoutUser),
    fork(fetchCallOfficers),
    fork(fetchRequestPhoneNumberLink),
    fork(fetchInmatePhoneNumber),
    fork(fetchLinkOrDeletePhoneNumber),
    fork(fetchInmateCalls),
    fork(fetchPlayAudio),
    fork(fetchUploadDocument),
    fork(fetchGeneratedBulkSaving),
    fork(fetchRetrieveBulkSavingFile),
    fork(fetchUploadBulkSaving),
    fork(fetchRecordedSaving),
    fork(fetchRetrieveBulkSavingFileDetails),
    fork(fetchRequestedApprovalOrRejection),
    fork(fetchGeneratedBulkTransaction),
    fork(fetchRetrieveBulkTransactionFile),
    fork(fetchUploadBulkTransaction),
    fork(fetchRecordedTransaction),
    fork(fetchRetrieveBulkTransactionFileDetails),
    fork(fetchRequestedApprovalOrRejectionTransaction),
    fork(fetchDeleteUserAccount),
    fork(fetchUserProfile),
    fork(fetchUpdateUserProfile),
    fork(fetchConfirmAppointment),
    fork(fetchCompletedVisit),
    fork(fetchGetAvailableTime),
    fork(fetchGlobalSummary),
    fork(fetchCheckVisitId),
    fork(fetchCheckWhoJoined),
    fork(fetchVideoVisits),
    fork(fetchPendingVisits),
    fork(fetchVisitDone),
    fork(fetchSaveDeviceSignature),
    fork(fetchGetDevices),
    fork(fetchLinkDeviceToVisit),
    fork(fetchAutoStartSession),
    fork(fetchStartMeeting),
    fork(getActivityLogData),
    fork(fetchRequestInmateCallHistory),
    fork(getRequestCallingSettings),
    fork(retrieveCallingSettings),
    fork(fetchPreviewOrderFile),
    fork(fetchGetCells),
    fork(fetchRequestBindFileAndCell),
    fork(fetchPurchaseOrderList),
    fork(fetchManageZones),
    fork(fetchEditOrder),
    fork(fetchUploadBulkAmount),
    fork(fetchVisitorItems),
    fork(fetchVisitorLimit),
    fork(fetchSetVisitorLimit),
    fork(fetchTransferInmate),
    fork(fetchCallInvoices),
    fork(fetchSendBulkSMS),
    fork(getInmateConactTemplate),
  ]);
}
