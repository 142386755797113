import { Alert, Button, Flex, Form, Modal, Select } from "antd";
import { IUserSelectorType } from "interfaces";
import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  clearTransferInmate,
  getInmateData,
  getPublicItemsData,
  transferInmateData,
} from "redux/actions";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const TransferInmateModal = ({
  open,
  onClose,
  inmate,
}: {
  open: boolean;
  onClose: () => void;
  inmate: number | string | undefined;
}) => {
  const dispatch = useDispatch();
  const [selectedFacility, setSelectedFacility] = useState<string>();
  const {
    transferInmate,
    getPublicItem: { data, loading },
  } = userSelector((transfer) => transfer);

  const onHandleCloseModal = () => {
    onClose();
    dispatch(clearTransferInmate());
  };

  const onSubmit = () => {
    dispatch(
      transferInmateData({
        facility: selectedFacility || "",
        inmate_code: inmate,
      })
    );
  };

  const onSelectedFacility = (value: string) => setSelectedFacility(value);

  useEffect(() => {
    if (transferInmate.data?.success) {
      toast.success(transferInmate.message);
      onHandleCloseModal();
      dispatch(
        getInmateData({
          inmateWithWallet: "NO",
        })
      );
    }

    if (transferInmate.error) {
      toast.error(transferInmate.message);
      dispatch(clearTransferInmate());
    }
  }, [transferInmate.error, transferInmate.data]);

  useEffect(() => {
    dispatch(getPublicItemsData());
  }, [dispatch]);

  return (
    <Modal
      title={"Transfer Inmate"}
      open={open}
      onCancel={onHandleCloseModal}
      footer={
        <Flex justify="space-between" style={{ width: "100%" }}>
          <Button onClick={onHandleCloseModal} type="primary" ghost danger>
            Cancel
          </Button>
          <Button
            loading={transferInmate?.loading}
            onClick={onSubmit}
            type="primary"
            ghost
          >
            Transfer
          </Button>
        </Flex>
      }
    >
      <Form layout="vertical">
        <Form.Item>
          <Alert
            type="warning"
            message={`You are about to transfer Inmate with this ${inmate} RCS case number`}
          />
        </Form.Item>
        <Form.Item label="Select facility">
          <Select
            loading={loading}
            onChange={onSelectedFacility}
            placeholder="Select Facility"
            options={data?.data?.facilities || []}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TransferInmateModal;
