import {
  DOWNLOAD_INMATE_CONTACT_TEMPLATE_CLEAR,
  DOWNLOAD_INMATE_CONTACT_TEMPLATE_FAIL,
  DOWNLOAD_INMATE_CONTACT_TEMPLATE_START,
  DOWNLOAD_INMATE_CONTACT_TEMPLATE_SUCCESS,
} from "constants/actionType/inmates";

export const downloadInmateContactTemplateData = () => ({
  type: DOWNLOAD_INMATE_CONTACT_TEMPLATE_START,
});

export const downloadInmateContactTemplatesSuccess = (data: any) => ({
  type: DOWNLOAD_INMATE_CONTACT_TEMPLATE_SUCCESS,
  payload: data,
});

export const downloadInmateContactTemplatesFail = (error: any) => ({
  type: DOWNLOAD_INMATE_CONTACT_TEMPLATE_FAIL,
  payload: error,
});

export const clearDownloadInmateContactTemplateStore = () => ({
  type: DOWNLOAD_INMATE_CONTACT_TEMPLATE_CLEAR,
});
