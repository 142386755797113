/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Modal,
  Divider,
  Table,
  Upload,
  Button,
  Select,
  Form,
  Flex,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import {
  UploadOutlined,
  DeleteOutlined,
  FileSearchOutlined,
  SearchOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../interfaces";
import {
  clearFetchCellsStore,
  clearMakeOrderStore,
  clearRequestBindFileAndCellStore,
  fetchCellsData,
  getOrderFileData,
  makeOrderData,
  previewOrderFileData,
  requestBindFileAndCellData,
} from "../../../redux/actions";
import { toast } from "react-toastify";
import ExcelTemplateDownload from "components/downloadTemplate";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface DataObject {
  [key: string]: string | number;
}

interface InmateData {
  name?: string;
  rcs_case_number?: number;
  zone?: string;
  opening_balance?: number;
  items: string;
  requested_quantities: number;
}

interface OrderFileUploadProps {
  open: boolean;
  onHandleCloseModal: () => void;
}

interface FetchCellsPayload {
  retrieve_zones_cells: boolean;
  retrieve_cells_village: boolean;
  retrieve_villages: boolean;
  retrieve_zones: boolean;
  retrieve_cells: boolean;
}

export const fetchCellsPayload: FetchCellsPayload = {
  retrieve_zones_cells: false,
  retrieve_cells_village: false,
  retrieve_villages: false,
  retrieve_zones: false,
  retrieve_cells: false,
};

const filterOrderFile = {
  file_name: "all",
  order_file_type: "all",
};

const baseColumns = [
  {
    title: "RCS Case Number",
    dataIndex: "rcs_case_number",
    key: "rcs_case_number",
  },
  {
    title: "Opening Balance",
    dataIndex: "opening_balance",
    key: "opening_balance",
  },
  {
    title: "System balance",
    dataIndex: "current_balance",
    key: "current_balance",
  },
  { title: "Items", dataIndex: "items", key: "items" },
  {
    title: "Requested Quantities",
    dataIndex: "requested_quantities",
    key: "requested_quantities",
  },
  {
    title: "Booked quantities",
    dataIndex: "booked_quantities",
    key: "booked_quantities",
  },
  { title: "Unit price", dataIndex: "unit_price", key: "unit_price" },
  {
    title: "Booked amount",
    dataIndex: "total_amount",
    key: "total_amount",
  },
  {
    title: "Total order amount",
    dataIndex: "total_order_amount",
    key: "total_order_amount",
    render: (total_amount: string, index: number) => (
      <Tag color="volcano" key={index}>
        {total_amount}
      </Tag>
    ),
  },
  { title: "Balance", dataIndex: "balance", key: "balance" },
];

const OrderFileUpload: React.FC<OrderFileUploadProps> = ({
  open,
  onHandleCloseModal,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState<DataObject[]>([]);
  const [responseData, setResponseData] = useState<InmateData[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [getFormattedData, setGetFormattedData] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const {
    previewOrderFile,
    fetchCells,
    getOrderFile,
    requestBindFileAndCell,
    makeOder,
  } = userSelector((user) => user);

  const previewOrderFiles =
    Array.isArray(previewOrderFile.data) && previewOrderFile?.data;
  const daysColumn = { title: "Days", dataIndex: "days", key: "days" };
  const includeDaysColumn = previewOrderFiles.some(
    (item: any) => item.days && item.days.trim() !== ""
  );

  const previewRequestResponseColumns = includeDaysColumn
    ? [...baseColumns, daysColumn]
    : baseColumns;

  const cells =
    Array.isArray(fetchCells.data?.data) &&
    fetchCells.data?.data.map(
      (item: { cell_name: string; id: number }, index: number) => ({
        key: index,
        label: item.cell_name,
        value: item.id,
      })
    );

  const filteredOrderFiles =
    getOrderFile.data &&
    Array.isArray(getOrderFile.data) &&
    getOrderFile.data.filter(
      (item: { active: boolean }) => item.active === true
    );

  const orderFiles = filteredOrderFiles.map(
    (item: { file_name: string; id: number }, index: number) => ({
      label: item.file_name,
      value: item.id,
      key: index,
    })
  );

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target?.result;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData: DataObject[] = XLSX.utils.sheet_to_json(sheet);
      setData(jsonData);

      const cols = Object.keys(jsonData[0]).map((key) => ({
        title: key,
        dataIndex: key,
        key,
      }));
      setColumns(cols);
    };
    reader.readAsBinaryString(file);
    return false;
  };

  const handleClear = () => {
    setData([]);
    setColumns([]);
    setResponseData([]);
    dispatch(clearFetchCellsStore());
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onHandleSelectCell = (value: string) => {
    form.setFieldsValue({ cell_id: value });
  };

  const onHandleSelectOrderFile = (value: string) => {
    form.setFieldsValue({ order_file: value });
  };

  const onHandleUploadOrderFile = () => {
    dispatch(previewOrderFileData({ data: getFormattedData }));
  };

  const onHandleSubmit = () => {
    const formData = form.getFieldsValue();
    dispatch(requestBindFileAndCellData(formData));
    saveOrderMade();
  };

  const saveOrderMade = () => {
    const formData = form.getFieldsValue();
    const dataToUpload: any = responseData;
    dispatch(
      makeOrderData({
        data_to_format: dataToUpload,
        order_file: formData?.order_file,
        data: [],
      })
    );
  };

  useEffect(() => {
    if (data) {
      const formattedData: any = formatKeys(data);
      setGetFormattedData(formattedData);
    }
  }, [data]);

  useEffect(() => {
    if (previewOrderFiles) {
      setResponseData(previewOrderFiles);
      setData([]);
    }
    if (previewOrderFiles) {
      dispatch(fetchCellsData({ ...fetchCellsPayload, retrieve_cells: true }));
      dispatch(getOrderFileData(filterOrderFile));
    }

    if (previewOrderFile?.error) {
      toast.error(previewOrderFile?.error?.message);
    }
    if (requestBindFileAndCell?.error) {
      toast.error(requestBindFileAndCell?.message);
    }
    if (requestBindFileAndCell?.data === null) {
      dispatch(clearRequestBindFileAndCellStore());
      handleClear();
    }
  }, [
    previewOrderFiles,
    previewOrderFile?.error,
    previewOrderFile?.data,
    requestBindFileAndCell?.error,
    requestBindFileAndCell?.data,
    makeOder?.error,
    makeOder?.data,
  ]);

  useEffect(() => {
    if (makeOder?.error) {
      toast.error(makeOder?.message);
    }
    if (makeOder?.data && !makeOder?.error) {
      toast.success(makeOder?.message);
      dispatch(clearMakeOrderStore());
    }
  }, [dispatch, makeOder?.error, makeOder?.data]);

  return (
    <Modal
      open={open}
      onCancel={onHandleCloseModal}
      footer={null}
      width={responseData?.length !== 0 ? 1500 : 1000}
    >
      <Flex style={{ width: "100%" }}>
        <ExcelTemplateDownload
          title="Download order file template"
          downloadUrl={"upload-order-file-excel-template"}
        />
      </Flex>
      <Flex
        align="center"
        justify="space-between"
        style={{ marginTop: "20px" }}
      >
        {!data?.length && !responseData?.length && (
          <Upload
            beforeUpload={handleFileUpload}
            accept=".xlsx, .xls"
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>Upload Order Request</Button>
          </Upload>
        )}
        {responseData?.length !== 0 && (
          <Form layout="inline" form={form} onFinish={onHandleSubmit}>
            <Form.Item
              name="order_file"
              label="Order files"
              rules={[{ required: true, message: "Select Order file" }]}
            >
              <Select
                loading={getOrderFile.loading}
                suffixIcon={<SearchOutlined />}
                options={orderFiles}
                placeholder="Select order file"
                style={{ width: "250px" }}
                onChange={onHandleSelectOrderFile}
              />
            </Form.Item>
            <Form.Item
              name="cell_id"
              rules={[{ required: true, message: "Select cell" }]}
            >
              <Select
                options={cells}
                placeholder="Select Cell to Add to Order"
                style={{ width: "200px" }}
                onChange={onHandleSelectCell}
              />
            </Form.Item>
            <Form.Item>
              <Button
                ghost
                loading={requestBindFileAndCell?.loading || makeOder?.loading}
                htmlType="submit"
                type="primary"
                icon={<SaveOutlined />}
              >
                Save File
              </Button>
            </Form.Item>
          </Form>
        )}
        {getFormattedData?.length !== 0 && (
          <Button
            ghost
            type="primary"
            onClick={onHandleUploadOrderFile}
            loading={previewOrderFile?.loading}
            icon={<FileSearchOutlined />}
          >
            Preview Order
          </Button>
        )}
        <Button
          danger
          icon={<DeleteOutlined />}
          onClick={handleClear}
          style={{ marginLeft: 8 }}
        >
          Clear
        </Button>
      </Flex>

      <Divider style={{ margin: "8px" }} />
      <div>
        {responseData?.length === 0 && (
          <Table
            dataSource={data}
            columns={columns}
            size="small"
            rowKey={(_record, index) =>
              index !== undefined ? index.toString() : ""
            }
          />
        )}
        {responseData?.length !== 0 && (
          <Table
            dataSource={responseData}
            columns={previewRequestResponseColumns}
            loading={previewOrderFile?.loading}
            rowKey={(_record, index) => index!.toString()}
            pagination={false}
            bordered
            size="small"
          />
        )}
      </div>
    </Modal>
  );
};

const toSnakeCase = (str: string) => {
  return str.replace(/\s+/g, "_").toLowerCase();
};

const formatKeys = (arr: DataObject[]): DataObject[] => {
  return arr.map((obj) => {
    const formattedObj: DataObject = {};
    Object.keys(obj).forEach((key) => {
      const newKey = toSnakeCase(key);
      formattedObj[newKey] = obj[key];
    });
    return formattedObj;
  });
};

export default OrderFileUpload;
