import React, { useEffect } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { IUserSelectorType } from "../../../../interfaces";
import {
  getInmatePhoneNumberData,
  linkOrDeletePhoneNumberData,
} from "../../../../redux/actions";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { Button, Spin, Switch } from "antd";
import { BsArrowLeft } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";
import { TiDeleteOutline } from "react-icons/ti";
import { IPhoneNumber } from "../../../../interfaces/linkPhoneNumber";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

const InmatePhoneNumber: React.FC = () => {
  const dispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const {
    state: { inmateId },
  } = useLocation();
  const {
    getUserData,
    linkOrDeletePhoneNumber,
    getInmatePhoneNumber: { data },
  } = userSelector((user) => user);

  const contacts: IPhoneNumber[] = data?.data;

  const removerContact = (id: number) => {
    dispatch(
      linkOrDeletePhoneNumberData({
        delete: true,
        link: false,
        id: id,
        status: "",
      })
    );
  };

  const linkPhoneNumber = (checked: boolean, id: number) => {
    dispatch(
      linkOrDeletePhoneNumberData({
        delete: false,
        link: true,
        id: id,
        status: checked,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getInmatePhoneNumberData(`prison/audio/inmate-bound-phone/${inmateId}`)
    );
  }, [dispatch, linkOrDeletePhoneNumber]);

  return (
    <>
      <div className="user-account-tbl">
        <div className="tbl-items">
          <div className="details-btn-group__left">
            <Button
              onClick={() => navigate("/inmates-list")}
              className="back-btn"
            >
              <BsArrowLeft size={22} />
              <span style={{ alignSelf: "center", marginLeft: "5px" }}>
                Inmate list
              </span>
            </Button>
          </div>
        </div>
        {contacts !== undefined ? (
          <>
            {contacts.length === 0 ? (
              <>
                {
                  <div className="empty-list">
                    <span className="empty-list__title">No results found</span>
                    <span className="empty-list__subtitle">{`Try reload again to find what you're looking for.`}</span>
                  </div>
                }
              </>
            ) : null}

            {contacts.length === undefined ? (
              <>
                {" "}
                <div style={{ margin: "20% auto", height: "800px" }}>
                  <Spin
                    style={{ color: " #282936" }}
                    indicator={
                      <LoadingOutlined style={{ fontSize: 30 }} spin />
                    }
                  />
                </div>
              </>
            ) : null}

            {contacts.length !== 0 ? (
              <div
                className="table-form__column"
                style={{ width: "100%", background: "transparent" }}
              >
                {contacts.map((contact: IPhoneNumber, index: number) => {
                  return (
                    <>
                      <div className="list-title" key={index}>
                        <div className="row">
                          <span className="list-title__title">
                            {contact.first_name} {contact.last_name}
                          </span>

                          {linkOrDeletePhoneNumber.loading ? (
                            <>
                              <div>
                                <Spin
                                  style={{
                                    color: " #282936",
                                    marginRight: "20px",
                                  }}
                                  indicator={
                                    <LoadingOutlined
                                      style={{ fontSize: 18 }}
                                      spin
                                    />
                                  }
                                />
                                <span>Updating...</span>
                              </div>
                            </>
                          ) : (
                            <>
                              {[2, 7, 5].includes(
                                getUserData?.data?.data[0]?.role_id
                              ) && (
                                <>
                                  <div className="icon-btn-right">
                                    <div className="icon-switch-btn">
                                      <span
                                        style={{
                                          color: contact.status
                                            ? "#282936"
                                            : "",
                                        }}
                                      >
                                        {contact.status ? "Linked" : "Unlinked"}
                                      </span>
                                      <Switch
                                        key={index}
                                        onChange={(checked) =>
                                          linkPhoneNumber(checked, contact?.id)
                                        }
                                        checked={contact.status}
                                        style={{
                                          backgroundColor: contact.status
                                            ? "#282936"
                                            : "",

                                          alignSelf: "center",
                                        }}
                                      />
                                    </div>
                                    <TiDeleteOutline
                                      color="red"
                                      onClick={() =>
                                        removerContact(contact?.id)
                                      }
                                      className="icon"
                                      size={22}
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>

                        <div className="list-title__subtitle">
                          <span>Phone: {contact.phone_number}</span>{" "}
                          <span>|</span>{" "}
                          <span>Relation ship: {contact.relation_ship}</span>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            ) : null}
          </>
        ) : (
          <div style={{ margin: "20% auto", height: "800px" }}>
            <Spin
              style={{ color: " #282936" }}
              indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default InmatePhoneNumber;
