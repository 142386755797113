/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  DOWNLOAD_INMATE_CONTACT_TEMPLATE_START,
  DOWNLOAD_INMATE_CONTACT_TEMPLATE_SUCCESS,
  DOWNLOAD_INMATE_CONTACT_TEMPLATE_FAIL,
  DOWNLOAD_INMATE_CONTACT_TEMPLATE_CLEAR,
} from "constants/actionType/inmates";

interface InitialState {
  data: [];
  error: any;
  loading: boolean;
}

const INITIAL_STATE: InitialState = {
  data: [],
  error: {},
  loading: false,
};

const downloadInmateContactTemplate = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case DOWNLOAD_INMATE_CONTACT_TEMPLATE_START: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case DOWNLOAD_INMATE_CONTACT_TEMPLATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    }

    case DOWNLOAD_INMATE_CONTACT_TEMPLATE_FAIL: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    }
    case DOWNLOAD_INMATE_CONTACT_TEMPLATE_CLEAR: {
      return {
        ...state,
        loading: false,
        error: null,
        data: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default downloadInmateContactTemplate;
